import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.$style.bar)
    }, [
        _createElementVNode("div", {
            class: "bar-in",
            style: _normalizeStyle({
                width: `${_ctx.progress}%`
            })
        }, null, 4)
    ], 2);
}
