export default [
    { id: 10000002, key: 'Ayaka', name: 3421, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000003, key: 'Qin', name: 3422, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000005, key: 'PlayerBoy', name: 3423, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000006, key: 'Lisa', name: 3424, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000007, key: 'PlayerGirl', name: 3425, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000014, key: 'Barbara', name: 3426, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000015, key: 'Kaeya', name: 3427, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000016, key: 'Diluc', name: 3428, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000020, key: 'Razor', name: 3429, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000021, key: 'Ambor', name: 3430, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000022, key: 'Venti', name: 3431, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000023, key: 'Xiangling', name: 3432, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000024, key: 'Beidou', name: 3433, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000025, key: 'Xingqiu', name: 3434, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000026, key: 'Xiao', name: 3435, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000027, key: 'Ningguang', name: 3436, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000029, key: 'Klee', name: 3437, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000030, key: 'Zhongli', name: 3438, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000031, key: 'Fischl', name: 3439, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000032, key: 'Bennett', name: 3440, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000033, key: 'Tartaglia', name: 3441, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000034, key: 'Noel', name: 3442, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000035, key: 'Qiqi', name: 3443, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000036, key: 'Chongyun', name: 3444, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000037, key: 'Ganyu', name: 3445, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000038, key: 'Albedo', name: 3446, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000039, key: 'Diona', name: 3447, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000041, key: 'Mona', name: 3448, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000042, key: 'Keqing', name: 3449, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000043, key: 'Sucrose', name: 3450, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000044, key: 'Xinyan', name: 3451, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000045, key: 'Rosaria', name: 3452, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000046, key: 'Hutao', name: 3453, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000047, key: 'Kazuha', name: 3454, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000048, key: 'Feiyan', name: 3455, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000049, key: 'Yoimiya', name: 3456, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000050, key: 'Tohma', name: 3457, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000051, key: 'Eula', name: 3458, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000052, key: 'Shougun', name: 3459, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000053, key: 'Sayu', name: 3460, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000054, key: 'Kokomi', name: 3461, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000055, key: 'Gorou', name: 3462, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000056, key: 'Sara', name: 3463, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000057, key: 'Itto', name: 3464, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000058, key: 'Yae', name: 3465, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000059, key: 'Heizo', name: 3466, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000060, key: 'Yelan', name: 3467, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000061, key: 'Momoka', name: 3468, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000062, key: 'Aloy', name: 3469, weapon: 'Bow', quailty: 'OrangeSp' },
    { id: 10000063, key: 'Shenhe', name: 3470, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000064, key: 'Yunjin', name: 3471, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000065, key: 'Shinobu', name: 3472, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000066, key: 'Ayato', name: 3473, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000067, key: 'Collei', name: 3474, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000068, key: 'Dori', name: 3475, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000069, key: 'Tighnari', name: 3476, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000070, key: 'Nilou', name: 3477, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000071, key: 'Cyno', name: 3478, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000072, key: 'Candace', name: 3479, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000073, key: 'Nahida', name: 3480, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000074, key: 'Layla', name: 3481, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000075, key: 'Wanderer', name: 3482, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000076, key: 'Faruzan', name: 3483, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000077, key: 'Yaoyao', name: 3484, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000078, key: 'Alhatham', name: 3485, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000079, key: 'Dehya', name: 3486, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000080, key: 'Mika', name: 3487, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000081, key: 'Kaveh', name: 3488, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000082, key: 'Baizhuer', name: 3489, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000083, key: 'Linette', name: 3490, weapon: 'SwordOneHand', quailty: 'Purple' },
    { id: 10000084, key: 'Liney', name: 3491, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000085, key: 'Freminet', name: 3492, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000086, key: 'Wriothesley', name: 3493, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000087, key: 'Neuvillette', name: 3494, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000088, key: 'Charlotte', name: 3495, weapon: 'Catalyst', quailty: 'Purple' },
    { id: 10000089, key: 'Furina', name: 3496, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000090, key: 'Chevreuse', name: 3497, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000091, key: 'Navia', name: 3498, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000092, key: 'Gaming', name: 3499, weapon: 'Claymore', quailty: 'Purple' },
    { id: 10000093, key: 'Liuyun', name: 3500, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000094, key: 'Chiori', name: 3501, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000095, key: 'Sigewinne', name: 3502, weapon: 'Bow', quailty: 'Orange' },
    { id: 10000096, key: 'Arlecchino', name: 3503, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000097, key: 'Sethos', name: 3504, weapon: 'Bow', quailty: 'Purple' },
    { id: 10000098, key: 'Clorinde', name: 3505, weapon: 'SwordOneHand', quailty: 'Orange' },
    { id: 10000099, key: 'Emilie', name: 3506, weapon: 'Pole', quailty: 'Orange' },
    { id: 10000100, key: 'Kachina', name: 3507, weapon: 'Pole', quailty: 'Purple' },
    { id: 10000101, key: 'Kinich', name: 3508, weapon: 'Claymore', quailty: 'Orange' },
    { id: 10000102, key: 'Mualani', name: 3509, weapon: 'Catalyst', quailty: 'Orange' },
    { id: 10000103, key: 'Xilonen', name: 3510, weapon: 'SwordOneHand', quailty: 'Orange' },
]
