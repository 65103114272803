import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    const _component_scriptx = _resolveComponent("scriptx");
    return _openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_router_view),
        _createVNode(_component_scriptx, {
            src: "https://static.geetest.com/v4/gt4.js"
        })
    ], 64);
}
