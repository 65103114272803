import { ref, defineComponent } from 'vue';
export default defineComponent({
    setup (props, { expose }) {
        const progress = ref(0);
        const setProgress = (value)=>{
            progress.value = value;
        };
        expose({
            setProgress
        });
        return {
            progress
        };
    }
});
