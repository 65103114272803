import bus from '@/bus';
import { defineComponent, ref, toRef } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
library.add(faEllipsisVertical);
export default defineComponent({
    props: {
        fullHeight: {
            type: Boolean,
            default: false
        }
    },
    setup () {
        const actionsOpen = ref(false);
        return {
            actionsOpen,
            isMobile: toRef(bus(), 'isMobile')
        };
    }
});
