import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_fa_icon = _resolveComponent("fa-icon");
    const _component_el_button = _resolveComponent("el-button");
    return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass({
            [_ctx.$style.fullHeight]: _ctx.fullHeight,
            [_ctx.$style.layout]: true,
            [_ctx.$style.open]: _ctx.actionsOpen
        })
    }, [
        _createElementVNode("header", {
            class: _normalizeClass(_ctx.$style.componentHeader)
        }, [
            _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.appTitle)
            }, [
                _renderSlot(_ctx.$slots, "title")
            ], 2),
            _ctx.$slots.actions ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([
                    _ctx.$style.appActions,
                    _ctx.actionsOpen ? _ctx.$style.open : ''
                ])
            }, [
                _renderSlot(_ctx.$slots, "actions")
            ], 2)) : _createCommentVNode("", true),
            _ctx.isMobile && _ctx.$slots.actions ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                class: _normalizeClass([
                    _ctx.$style.btnActions,
                    _ctx.$style.boxActions,
                    _ctx.actionsOpen ? _ctx.$style.open : ''
                ]),
                plain: "",
                onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.actionsOpen = !_ctx.actionsOpen)
            }, {
                default: _withCtx(()=>[
                        _createVNode(_component_fa_icon, {
                            icon: "ellipsis-vertical"
                        })
                    ]),
                _: 1
            }, 8, [
                "class"
            ])) : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("main", {
            class: _normalizeClass(_ctx.$style.componentMain)
        }, [
            _renderSlot(_ctx.$slots, "default")
        ], 2)
    ], 2);
}
